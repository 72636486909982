/**
 *  项目的配置文件
 */
// export const apiUrl = 'https://juyuan-b2b2c.dev.juyuansoft.com/';//接口请求地址
export const apiUrl = 'http://139.129.18.219:8001/'; //演示环境地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const chatUrl = 'wss://139.129.18.219:8001/';//客服地址
export const title = '岂知巢';//浏览器顶部title
export const gdKey = 'c6979f9121ed9d1d6cd46f5fc2ab30e3';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试


// ** copyright *** juyuan *** version-v3.4 *** date-2021-10-20 ***主版本v3.4



